import React, { Component } from 'react';
import '././../../../../Styles/GeoThermal.scss';
import * as ReactDOM from 'react-dom';
import CustomImg from './../../../Global/CustomImg/CustomImg';
import ReactCodeInput from './../../../Global/ReactCodeInput/ReactCodeInput';
import Draggable from "react-draggable";
import { const_basePage } from '../../../Global/Constants/constants';
import correctAudioURL from './../../../../audios/correct.mp3';
import incorrectAudioURL from './../../../../audios/incorrect.mp3';

class GeoThermal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            AnswerSheet: [],
            CurrentTask: {},
            taskId: 0,
            draggableImages: [
                { id: 1, name: "music01.svg", isDragged: false },
                { id: 2, name: "music02.svg", isDragged: false },
                { id: 3, name: "music03.svg", isDragged: false },
                { id: 4, name: "music04.svg", isDragged: false },
                { id: 5, name: "music05.svg", isDragged: false }
            ],
            draggedPosition: [
                { name: "", isDragged: false },
                { name: "", isDragged: false },
                { name: "", isDragged: false },
                { name: "", isDragged: false },
                { name: "", isDragged: false }
            ],
            lastPostion: false,
            draggedElement: 0,
            draggedElementText: "",
            isGameStepCompleted: false,
            isAllDraggableCompleted: false,
            CurrentPosition : [ 0, 0 ,0 ,0, 0],
            password : "",
            isGameFinish : false,
            isAudioPlay : false,
            incorrectAudioURL : incorrectAudioURL,
            correctAudioURL : correctAudioURL,
        };
    }

    componentDidMount()
    {
        const isIE = /*@cc_on!@*/false || !!document.documentMode;
        const isEdge = !isIE && !!window.StyleMedia;
        this.setState({
            isEdge: isEdge
        });
    }

    static getDerivedStateFromProps(props, state) {
        if (props.CurrentTask !== state.CurrentTask) {
            let currentTask = props.CurrentTask;
            let currentTaskSolutions = currentTask.Solutions;
            if (currentTask.Solutions !== undefined) {
                let stepCompleted = false;
                if(currentTask.Progress !== undefined && currentTask.Progress.length > 0)
                {
                    const verifyStepProgress = currentTask.Progress.filter(m=> m.type.trim().toLowerCase() === "step");
                    if(verifyStepProgress.length > 0)
                    {
                        stepCompleted = true;
                    }
                }
                return {
                    AnswerSheet: currentTaskSolutions,
                    taskId: currentTask.id,
                    isGameStepCompleted : stepCompleted,
                    isAllDraggableCompleted : stepCompleted,
                }
            }
        }
        return null;
    }

    resetAll = () => {
        const updatedDraggableImages = this.state.draggableImages.filter(data => {
          data.isDragged = false;
          return  data;
        });
        const updatedDraggedPosition = this.state.draggedPosition.filter(data => {
            data.isDragged = false;
            data.name = "";
            return data;
        });
        this.setState({
            draggableImages: updatedDraggableImages,
            draggedPosition: updatedDraggedPosition,
            CurrentPosition: [0, 0, 0, 0, 0]
        });
    }

    onStop = async (e, data, index) => {
        const mouseangle = e.x;
        let isInside = false;
        let inputId = 0;
        if (this.state.lastPostion) {
            [...Array(5)].map((data, key) => {
                const txtField = ReactDOM.findDOMNode(this.refs["txt" + (key + 1)]).getBoundingClientRect();
                if (this.state.isEdge) {
                    if (mouseangle > txtField.left && mouseangle < (txtField.left + txtField.width)) {
                        inputId = key;
                        isInside = true;
                    }
                } else {
                    if (mouseangle > txtField.x && mouseangle < (txtField.x + txtField.width)) {
                        inputId = key;
                        isInside = true;
                    }
                }
            });
        }

        if (isInside) {
            let updatedDraggedPosition = this.state.draggedPosition;
            if (!updatedDraggedPosition[inputId].isDragged) {
                let updatedPosition = this.state.draggableImages;
                updatedPosition[this.state.draggedElement].isDragged = true;

                updatedDraggedPosition[inputId].isDragged = true;
                updatedDraggedPosition[inputId].name = updatedPosition[this.state.draggedElement].name;

                let updatedCurrentPosition = this.state.CurrentPosition;
                updatedCurrentPosition[inputId] = this.state.draggedElement + 1;

                this.setState({
                    draggableImages: updatedPosition,
                    draggedPosition: updatedDraggedPosition,
                    CurrentPosition: updatedCurrentPosition
                });
            }
        }

        this.setState({
            draggedElement: 0,
            lastPostion: false
        });
    }

    onStart = (index, elementTxt) => {
        this.setState({
            draggedElement: index,
            draggedElementText: elementTxt
        });
    }

    onDrag = (e, data) => {
        if (!this.state.lastPostion && e.y < 350) {
            this.setState({
                lastPostion: true
            });
        }
    }

    verification = async () => {
       if(!this.state.isGameStepCompleted)
       {
            const answers = this.state.AnswerSheet[0].answer_key[0];
            let allCorrect = true;
            this.state.CurrentPosition.map((data , index) =>{ 
              
                if(data !== answers.POSITION[index])
                {
                    allCorrect = false;
                }
            })

            if(!allCorrect)
            {
                this.setState({
                    isAudioPlay : true
                });
                this.resetAll();
            }else{
                this.setState({
                    isAllDraggableCompleted: true,
                    isAudioPlay : true
                });
                await this.props.markStepAsCompleted(this.state.taskId,1);
            }
       }
    }

    verifyFinalAnswers = async (value) => {
        this.setState({
            password: value
        });

        if (this.state.AnswerSheet && this.state.AnswerSheet.length > 0 && this.state.AnswerSheet[0] && this.state.AnswerSheet[0].answer_key && this.state.AnswerSheet[0].answer_key.length > 0) {
            const answers = this.state.AnswerSheet[0].answer_key[0];
            const passcode = answers.PASSWORD;
            if (passcode.trim().toLowerCase() === value.trim().toLowerCase()) {
                this.setState({
                    isGameFinish: true
                });
                await this.props.markAsCompleted(this.state.taskId);
            }
        }
    }
        
    render() {
        return (
            <React.Fragment>
                <div id="container" className="position-absolute d-flex jistify-content-center">
                    <div className="page_content">
                        <div className={"GeoThermal-holder " + (this.state.SelectedSatellite > 0 ? 'show-overlay' : '')}>
                            <div className="topBox">
                                <div className="row h-100">
                                    <div className="col-2"></div>
                                    <div className="col-8 pt-5 pb-5">
                                        <div className="toptitle text-center">match the image</div>
                                        <div className="matchBox-wrapper">
                                            <div className="matchBox d-flex">
                                                {!this.state.isGameStepCompleted && this.state.draggedPosition.map((data, index) => {
                                                    return <div id={"txt" + (index + 1)} key={index} ref={"txt" + (index + 1)} className="itemBox">
                                                        {data.isDragged &&
                                                            <div className="imgBoxwrapper">
                                                                <div className="imgBox">
                                                                    <CustomImg src={data.name} width="80" className="rounded-circle" alt="Sound Waves" />
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                })}

                                                {this.state.isGameStepCompleted && this.state.AnswerSheet && this.state.AnswerSheet.length > 0 &&
                                                 this.state.AnswerSheet[0].answer_key && this.state.AnswerSheet[0].answer_key.length > 0 && 
                                                 this.state.AnswerSheet[0].answer_key[0].POSITION && this.state.AnswerSheet[0].answer_key[0].POSITION.length > 0 &&
                                                 this.state.AnswerSheet[0].answer_key[0].POSITION.map((data, index) => {
                                                return <div className="itemBox correct">
                                                            <div className="imgBoxwrapper">
                                                                <div className="imgBox">                                                                    
                                                                    <CustomImg src={"complete-music0" + data +".svg"} width="80" className="rounded-circle" alt="Sound Waves" />
                                                                </div>
                                                            </div>
                                                    </div>
                                                })}
                                            </div>
                                        </div>
                                        <div className="passwordBox mt-5 pt-3">
                                            <div className="passText">Password:</div>
                                            <div className="inputBoxwrapper">
                                                <ReactCodeInput disabled={this.state.isGameFinish} onChange={this.verifyFinalAnswers} type='text' fields={5} pattern="\d*" className={"code-input"} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-2 d-flex align-items-center justify-content-center">
                                        {this.state.isAudioPlay && this.state.isAllDraggableCompleted &&
                                            <iframe className="d-none" src={correctAudioURL + "?" + Math.random()} allow="autoplay"></iframe>
                                        }
                                        {this.state.isAudioPlay && !this.state.isAllDraggableCompleted &&
                                            <iframe className="d-none" src={incorrectAudioURL + "?" + Math.random()} allow="autoplay"></iframe>
                                        }
                                        <div onClick={this.verification} className="cursor"><CustomImg src="play-img.svg" width="80" className="rounded-circle" alt="Play Icon" /></div>
                                    </div>
                                </div>
                            </div>
                            <div className="bottomBox">
                                <div className="row h-100 justify-content-center d-flex justify-content-between">
                                    <div className="col-2"></div>
                                    <div className="col-8">
                                        <div className="row">
                                            {!this.state.isAllDraggableCompleted && this.state.draggableImages.map((data, index) => {
                                                return <Draggable key={index} onDrag={this.onDrag} onStart={() => this.onStart(index, data.id)} key={index} onStop={(e, data) => this.onStop(e, data, index)} position={{ x: 0, y: 0 }} scale={const_basePage.scale}>
                                                    <div className="col">
                                                        {!data.isDragged &&
                                                            <div className="imgBoxwrapper">
                                                                <div className="imgBox">
                                                                    <CustomImg src={data.name} className="rounded-circle" alt={data.name} />
                                                                </div>
                                                            </div>}
                                                    </div>
                                                </Draggable>
                                            })}
                                        </div>
                                    </div>
                                    <div className="col-2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default GeoThermal;