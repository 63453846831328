import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import History from '../../Providers/History';
import CustomImg from '../Global/CustomImg/CustomImg';

class Header extends Component {

    state = {
        currentTask: 0,
        isAllGamesComplete : false
    }

    async componentDidMount() {
        await this.props.getEnrolled();
        await this.props.getAllTasks();
    }

    static getDerivedStateFromProps(props) {      
        if (!props.isExperienceCompleted && !props.isExperienceGameFinished) {
            History.push('/video/experience');
        } else if (props.isExperienceGameFinished) {
            History.push('/');
        }
        else {
            if (props.currentTask.gate_order >= 0) {
                let { id: task_id, isVideoPlayed, isMsgPoped, message, isCompleted , gate_order} = props.currentTask;
                if (props.isGameFinish) {
                    History.push('/video/finish');
                } else if (!isVideoPlayed) {                    
                    History.push('/video/' + gate_order);                  
                } else {
                    /*if(task_id === 6 &&  !isMsgPoped){
                        History.push('/');
                    }else {*/
                        History.push('/task' + parseInt(gate_order));
                    //}
                    
                }
                return {
                    currentTask: task_id,
                    isAllGamesComplete : props.isGameFinish
                };
            }
        }
        return null;
    }

    completeTask = async () => {    
        if(process.env.REACT_APP_COMPLETE_GAME_ON_CLICK === "1") {          
            await this.props.markAsCompleted(this.state.currentTask);
        }
    }

    render() {
        let { id: task_id, isVideoPlayed, isMsgPoped, isDashboardActive , gate_order } = this.props.currentTask;

        return (<React.Fragment>
            <header className="top-header">
                <div className="header-profile">
                    <div className="profileBox">
                        <div className="profileBox-imgbox" onClick={this.completeTask}>
                            <CustomImg src="man-icon.svg" className="" alt="Profile Image" />
                        </div>
                    </div>
                    <div className="profileContent">
                        <div className="user-name text-truncate" onClick={this.completeTask}>CYBER_SNAKE</div>
                        <small className="w-100 user-nic-name text-center text-truncate text-white">{this.props.nickname}</small> 
                        <Link className="edit-profile-link" to="/profile">
                            <CustomImg width="40" src="edit-prfile-icon.svg" className="" alt="Profile icon img" />
                            EDIT PROFILE</Link>
                    </div>
                </div>
            </header>
            {this.props.tasks &&
                <div className="taskBox">
                    {this.props.tasks.map((data, key) => {
                        const index = key + 1;
                        let taskType = "incomplete";
                        let imageName = "topTask-incomplete" + data.gate_order + "Icon.svg";
                        if(data.isCompleted)
                        {
                            taskType = "completed";
                            imageName= "topTask" + data.gate_order + "Icon.svg";
                        }else if(data.gate_order === gate_order)
                        {
                            taskType = "current";
                            imageName= "topTask" + data.gate_order + "Icon.svg";
                        }

                        // let isAccessible = true;
                        

                        if (this.state.isAllGamesComplete) {
                            return <div className="itembox" key={index}>
                                <Link className={"btn completed"} to={'#'}>
                                    <span>
                                        <CustomImg src={"topTask" + index + "Icon.svg"} className="" alt="Task Bar icon" />
                                    </span>
                                </Link>
                            </div>
                        } else {
                            return <div className="itembox" key={index}>
                                <Link className={"btn " + taskType} to={(task_id === data.gate_order ? '/task' + (data.gate_order) : '#')}>
                                    <span>
                                        <CustomImg src={imageName} className="" alt="Task Bar icon" />
                                    </span>
                                </Link>
                                {/* to={(task_id === data.gate_order) && (isAccessible) ? '/task' + (data.gate_order) : '#'} */}
                                {/* {<Link className="btn" to={(task_id == (data.gate_order)) ? '/task'+(data.gate_order+1):'#'}>
                                        <span></span>
                                    </Link>} */}
                            </div>
                        }
                    })}

                </div>
            }
            <div className="topActionBox">
                <div className="buttons-item">
                    <Link className="btn" to={(task_id === 0 && isDashboardActive === false) ? '#' : '/video'} >
                        <span><em className="icon-playicon moonicon"></em></span>
                    </Link>
                </div>
                <div className="buttons-item">
                    <Link className="btn" to={(task_id === 0 && isDashboardActive === false) ? '#' : '/files'}>
                        <span><em className="icon-foldericon moonicon"></em></span>
                    </Link>
                </div>
            </div>
        </React.Fragment>);
    }
}

export default Header;