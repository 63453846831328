import React, { Component } from 'react';
import CustomScrollbars from './../../Global/CustomScrollbars/CustomScrollbars';
import History from '../../../Providers/History';
import CustomImg from '../../Global/CustomImg/CustomImg';

class Files extends Component {
    constructor() {
        super();
        this.state = {
            files: []
        };
    }

    async componentDidMount() {

        const {gate_order:task_id} = this.props.currentTask;       
        this.setState({
            task_id : task_id
        });
        
        if (this.props.isExperienceGameFinished) {
            const filesCollection = await this.props.getAllFiles(6);
            this.setState({
                files: filesCollection
            });
        } else {
            const { gate_order: task_id } = this.props.currentTask;
            const filesCollection = await this.props.getAllFiles(task_id);
            this.setState({
                files: filesCollection
            });
        }
    }

    download(file) {
        window.open(require('../../../datasets/' + file));
    }

    closeSection = () =>
    {
        if(this.props.isGameFinish)
        {
            History.push('/finish');
        }else{
            History.push('/task' + this.state.task_id);
        }
    }

    render() {
        return (<React.Fragment>
            <div className="videos-holder mainBox files-holder">
                <div className="play-icon">
                    <em className="icon-foldericon moonicon"></em>
                </div>
                <div className="container-close" onClick={this.closeSection}>
                    <CustomImg src="gradiant-close.svg" className="container-close float-right" alt="gradiant-close" />                
                </div>
                <CustomScrollbars>
                    <div id="videos-scrollbar">
                        <div className="video-Content files-content">
                            <div className="row">
                                {this.state.files && this.state.files.map((data, index) => {
                                    const fileName = data.split('.');
                                    return (<div key={index} className="col">
                                        <div className="video-list position-relative">
                                            <div>
                                                <div className="files-img">
                                                    <CustomImg src="gradiant-folder.svg" alt="files-img" />
                                                </div>
                                                <h4 role="link" onClick={() => this.download(data)} className="title text-truncate stretched-link">{fileName[0]}</h4>
                                            </div>
                                        </div>
                                    </div>)
                                })}
                            </div>
                        </div>
                    </div>
                </CustomScrollbars>
            </div>
        </React.Fragment >);
    }
}

export default Files;