import {MessageApi} from '../../Providers/Api'
const MODULE_NAME = 'Message';

const GET_ALL_MESSAGE = "teg/GET_ALL_MESSAGE";
const MARK_MESSAGE_AS_READ = "teg/MARK_MESSAGE_AS_READ";

const initialState = {
    data:[]
};

const MessageReducer = (state = initialState, action) => {
    let action_error = null;

    if (typeof action.error !== 'undefined') {
        action_error = action.error;
    }

    switch (action.type) {

        case GET_ALL_MESSAGE:
            let messagesT = state.data;
            messagesT = action.data[0];            
            return {
                ...state,  data: messagesT
            }

      /*  case MARK_MESSAGE_AS_READ:
            let messagesT = state.data;
            messagesT = action.data[0];            
            return {
                ...state,  data: messagesT
            }*/
        default:
            return state;
    }
};

export const getTaskMessage = (tid) => (dispatch , getState) => {
    try {
        const currentState = getState(); 
        console.log("caleled" , tid);
    
        const taskMessage = currentState.TaskReducer.tasks.filter((data, key) => { return data.gate_order === tid });
        console.log(taskMessage);
        return taskMessage;
    } catch (e) {
        // console.log("Error in fetching Data");
       // dispatch({ type: FETCH_FAILURE, error: e });
       // dispatch(error('Could not get user.', parseErrors(e)));
    }
};

export const markMessageAsRead = (msgId) => (dispatch) => {
    try {
        MessageApi.markMessageAsRead(msgId);
        let response = MessageApi.markMessageAsRead(msgId);              
        dispatch({ type: MARK_MESSAGE_AS_READ, data:response });
        return response;
    } catch (e) {
        // console.log("Error in fetching Data");
       // dispatch({ type: FETCH_FAILURE, error: e });
       // dispatch(error('Could not get user.', parseErrors(e)));
    }
};

export default MessageReducer;

//export const { sortChange, sortReset, singleSortReset } = sortable.actionCreators;
