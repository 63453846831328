import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "./../PageContainer/Home/Home";
import EndGame from "./../../Containers/PageContainer/TimeBonus/TimeBonus";
import Profile from "./../../Containers/PageContainer/Profile/Profile";
import Video from "./../../Containers/PageContainer/Video/Video";
import Message from "./../../Containers/PageContainer/Message/Message";
import Files from "./../../Containers/PageContainer/Files/Files";
import States from "./../../Containers/PageContainer/Games/States";
import Coal from "./../../Containers/PageContainer/Games/Coal";
import GeoThermal from "../../Containers/PageContainer/Games/GeoThermal";
import HydroElectric from "../../Containers/PageContainer/Games/HydroElectric";
import Nuclear from "./../../Containers/PageContainer/Games/Nuclear";
import Wind from "./../../Containers/PageContainer/Games/Wind";
import FinalTask from "./../../Containers/PageContainer/Games/FinalTask";
import { const_pageHeight, const_pageWidth, const_basePage } from '../Global/Constants/constants';
import $ from "jquery";
import _ from "lodash";

var pageHeight = 0,
    pageWidth = 0;
var basePage = {
    width: 1800,
    height: 900,
    scale: 1,
    scaleX: 1,
    scaleY: 1
};

class Main extends React.Component {
    constructor() {
        super();
        this.state = {
            screen_scale: {}
        }

    }


    getPageSize = () => {
        pageHeight = $("#container").height();
        pageWidth = $("#container").width();
    };

    scalePages(page, maxWidth, maxHeight) {
        var scaleX = 1,
            scaleY = 1;
        scaleX = maxWidth / const_basePage.width;
        scaleY = maxHeight / const_basePage.height;
        const_basePage.scaleX = scaleX;
        const_basePage.scaleY = scaleY;
        const_basePage.scale = scaleX > scaleY ? scaleY : scaleX;

        var newLeftPos = Math.abs(
            Math.floor((const_basePage.width * const_basePage.scale - maxWidth) / 2)
        );
        var newTopPos = Math.abs(
            Math.floor((const_basePage.height * const_basePage.scale - maxHeight) / 2)
        );

        page.attr(
            "style",
            "-webkit-transform:scale(" +
            const_basePage.scale +
            ");left:" +
            newLeftPos +
            "px;top:" +
            newTopPos +
            "px;"
        );
    }

    updateScreen = () => {
        var $page = $(".page_content");       
        this.getPageSize();
        this.scalePages($page, pageWidth, pageHeight);
    };

    componentDidMount() {
        window.addEventListener("resize", this.updateScreen);
        this.updateScreen();
        window.dispatchEvent(new Event('resize'));
    }

    componentDidUpdate() {
        this.updateScreen();
    }


    render() {
        return (
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/profile" component={Profile} />
                <Route exact path="/video/:tid" component={Video} />
                <Route exact path="/video" component={Video} />
                <Route exact path="/message" component={Message} />
                <Route exact path="/files" component={Files} />
                <Route exact path="/task1" component={States} />
                <Route exact path="/task2" component={Coal} />
                <Route exact path="/task3" component={GeoThermal} />
                <Route exact path="/task4" component={HydroElectric} />
                <Route exact path="/task5" component={Nuclear} />
                <Route exact path="/task6" component={Wind} />
                <Route exact path="/task7" component={FinalTask} />
                <Route exact path="/finish" component={EndGame} />
            </Switch>
        );
    }
}
export default Main;
