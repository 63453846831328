import React, { Component } from 'react';
import '././../../../../Styles/HydroElectric.scss';
import CustomImg from './../../../Global/CustomImg/CustomImg';
class HydroElectric extends Component {
    constructor(props) {
        super(props);
        this.state = {
            AnswerSheet: [],
            CurrentTask: {},
            taskId: 0,
            Switches : ["DOWN","DOWN","DOWN","DOWN","DOWN","DOWN","DOWN"]
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.CurrentTask !== state.CurrentTask) {
            let currentTask = props.CurrentTask;
            let currentTaskSolutions = currentTask.Solutions;
            if (currentTask.Solutions !== undefined) {
                return {
                    AnswerSheet: currentTaskSolutions,
                    taskId: currentTask.id
                }
            }
        }
        return null;
    }

    switchToggle = (index) => {
       
        const currentStatus =  this.refs["Switch" + index].value;
        let updatedArray = this.state.Switches;
        if(currentStatus.trim().toLowerCase() === "down")
        {
            this.refs["Switch" + index].value = "UP";
        }else{
            this.refs["Switch" + index].value = "DOWN";
        }

        if(updatedArray[index].trim().toLowerCase() === "down")
        {
            updatedArray[index] = "UP";
        }else{
            updatedArray[index] = "DOWN";
        }

        this.setState({
            Switches : updatedArray
        });
        this.verifyAnswers();
    }

    verifyAnswers = async () => {
        if(this.state.AnswerSheet && this.state.AnswerSheet.length > 0 && this.state.AnswerSheet[0] && this.state.AnswerSheet[0].answer_key && this.state.AnswerSheet[0].answer_key.length > 0)
        {
            const answers = this.state.AnswerSheet[0].answer_key[0];
            const currentAnswerCollection = answers.SWITCHES.split('');
           
            let allCorrected = true;
            currentAnswerCollection.map((data,index) => {
                const currentValue = (this.state.Switches[index].trim().toLowerCase() === "down") ? "0" : "1";
                if(parseInt(data.trim().toLowerCase()) !== parseInt(currentValue))
                {
                    allCorrected = false;
                }
            });

            if(allCorrected)
            {
                await this.props.markAsCompleted(this.state.taskId);
            }
        }
    }
    
    render() {
        return (
            <React.Fragment>
                <div id="container" className="position-absolute d-flex jistify-content-center">
                    <div className="page_content">
                        <div className={"hydroElectric-holder" + (this.state.SelectedSatellite > 0 ? 'show-overlay' : '')}>
                            <div className="h-100 d-flex justify-content-center align-items-center">
                                <div className="swichBox">
                                    <div className="innerBackground">
                                        <div className="swichBoxwrapper">
                                        <div className="swichItem"><input onClick={() => this.switchToggle(0)} type="checkbox" ref={"Switch0"} value="DOWN"/><label for="Switch0"></label></div>
                                            <div className="swichItem"><input onClick={() => this.switchToggle(1)} type="checkbox" ref={"Switch1"} value="DOWN" /><label for="Switch1"></label></div>
                                            <div className="swichItem"><input onClick={() => this.switchToggle(2)} type="checkbox" ref={"Switch2"} value="DOWN" /><label for="Switch2"></label></div>
                                            <div className="swichItem"><input onClick={() => this.switchToggle(3)} type="checkbox" ref={"Switch3"} value="DOWN" /><label for="Switch3"></label></div>
                                            <div className="swichItem"><input onClick={() => this.switchToggle(4)} type="checkbox" ref={"Switch4"} value="DOWN" /><label for="Switch4"></label></div>
                                            <div className="swichItem"><input onClick={() => this.switchToggle(5)} type="checkbox" ref={"Switch5"} value="DOWN" /><label for="Switch5"></label></div>
                                            <div className="swichItem"><input onClick={() => this.switchToggle(6)} type="checkbox" ref={"Switch6"} value="DOWN" /><label for="Switch6"></label></div>                                                                                      
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>                        
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default HydroElectric;