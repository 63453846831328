import React, { Component } from 'react';
import '././../../../../Styles/Wind.scss';
import CustomImg from './../../../Global/CustomImg/CustomImg';

class Wind extends Component {
    constructor(props) {
        super(props);
        this.state = {
            AnswerSheet:[],
            CurrentTask: {},
            allCorrect : false,
            taskId: 0
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.CurrentTask !== state.CurrentTask) {
            let currentTask = props.CurrentTask;
            let currentTaskSolutions = currentTask.Solutions;
            if (currentTask.Solutions !== undefined) {
                return {
                    AnswerSheet: currentTaskSolutions,
                    taskId: currentTask.id
                }
            }
        }
        return null;
    }

    UpNumber = (index) =>{ 
        if(!this.state.allCorrect)
        {
            const txtValue = this.refs["txt" + index].value;
            if(parseInt(txtValue) < 9)
            {
                this.refs["txt" + index].value = parseInt(txtValue) + 1;
                this.verifyAnswers();
            }
        }
    }

    DownNumber = (index) =>{ 
        if(!this.state.allCorrect)
        {
            const txtValue = this.refs["txt" + index].value;
            if(parseInt(txtValue) > 0)
            {
                this.refs["txt" + index].value = parseInt(txtValue) - 1;
                this.verifyAnswers();
            }
        }
    }

    verifyAnswers = async () => {
        if(this.state.AnswerSheet && this.state.AnswerSheet.length > 0 && this.state.AnswerSheet[0] && this.state.AnswerSheet[0].answer_key && this.state.AnswerSheet[0].answer_key.length > 0)
        {
            const answers = this.state.AnswerSheet[0].answer_key[0];
            let currentAnswerCollection = "";
            [...Array(5)].map((data, index) => {
                const txtValue = this.refs["txt" + index].value;
                currentAnswerCollection = currentAnswerCollection + txtValue.toString().trim().toLowerCase();
            });

            const passcode = answers.CODE;
            if(passcode.trim().toLowerCase() === currentAnswerCollection.trim().toLowerCase())
            {
                this.setState({
                    allCorrect : true
                });
                await this.props.markAsCompleted(this.state.taskId);
            }
        }
    }

    componentDidMount(){
        this.refs["txt0"].value = "0";
        this.refs["txt1"].value = "0";
        this.refs["txt2"].value = "0";
        this.refs["txt3"].value = "0";
        this.refs["txt4"].value = "0";
    }
    
    render() {
        return (
            <React.Fragment>
                <div id="container" className="position-absolute d-flex jistify-content-center">                     
                    <div className="page_content">
                        <div className={"wind-holder " + (this.state.SelectedSatellite > 0 ? 'show-overlay' : '')}>
                            <div className="h-100 d-flex justify-content-center align-items-center">
                            <div className="innerBackground">
                                <div className={"counterBox" + (this.state.allCorrect ? " complete" : "")}>
                                        <div className="counterItem">
                                            <button onClick={() => this.UpNumber(0)}>
                                                <CustomImg src="counter-top-arrow.svg" width="50" className="rounded-circle" alt="Counter Top Arrow" />
                                            </button>
                                            <input disabled={this.state.allCorrect}  ref={"txt0"} type="text" readOnly />
                                            <button onClick={() => this.DownNumber(0)}>
                                                <CustomImg src="counter-bottom-arrow.svg" width="50" className="rounded-circle" alt="Counter Bottom Arrow" />
                                            </button>
                                        </div>

                            <div className="counterItem">
                                <button onClick={() => this.UpNumber(1)}><CustomImg src="counter-top-arrow.svg" width="50" className="rounded-circle" alt="Counter Top Arrow" /></button>
                                <input disabled={this.state.allCorrect}  ref={"txt1"} type="text"  readOnly />
                                <button onClick={() => this.DownNumber(1)}><CustomImg src="counter-bottom-arrow.svg" width="50" className="rounded-circle" alt="Counter bottom Arrow" /></button>
                            </div>

                            <div className="counterItem">
                                <button onClick={() => this.UpNumber(2)}><CustomImg src="counter-top-arrow.svg" width="50" className="rounded-circle" alt="Counter Top Arrow" /></button>
                                <input disabled={this.state.allCorrect} ref={"txt2"} type="text"  readOnly  />
                                <button onClick={() => this.DownNumber(2)}><CustomImg src="counter-bottom-arrow.svg" width="50" className="rounded-circle" alt="Counter Bottom Arrow" /></button>
                            </div>

                            <div className="counterItem">
                                <button onClick={() => this.UpNumber(3)}><CustomImg src="counter-top-arrow.svg" width="50" className="rounded-circle" alt="Counter Top Arrow" /></button>
                                <input  disabled={this.state.allCorrect}  ref={"txt3"} type="text"  readOnly />
                                <button onClick={() => this.DownNumber(3)}><CustomImg src="counter-bottom-arrow.svg" width="50" className="rounded-circle" alt="Counter bottom Arrow" /></button>
                            </div>

                            <div className="counterItem">
                                <button onClick={() => this.UpNumber(4)}><CustomImg src="counter-top-arrow.svg" width="50" className="rounded-circle" alt="Counter Top Arrow" /></button>
                                <input disabled={this.state.allCorrect}  ref={"txt4"} type="text"  readOnly  />
                                <button onClick={() => this.DownNumber(4)}><CustomImg src="counter-bottom-arrow.svg" width="50" className="rounded-circle" alt="Counter bottom Arrow" /></button>
                            </div>
                            </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Wind;