import React, { Component } from "react";
import CustomScrollbars from "./../../Global/CustomScrollbars/CustomScrollbars";
import * as ReactDOM from "react-dom";
import parse from "html-react-parser";
import CustomImg from '../../Global/CustomImg/CustomImg';

class Note extends Component {

  constructor(props) {
    super(props);
    let task_list = this.props.tasks.filter((data) => { return data.Tooltips.length > 0 });
    let current_task = {}
    if (this.props.currentTask && this.props.currentTask.Tooltips && this.props.currentTask.Tooltips.length > 0) {
      current_task = this.props.currentTask;
    } else {
      current_task = task_list[0];
    }
    this.state = {
      task_tooltips: this.props.tooltips,
      current_tasks: current_task,
      tasks: task_list
    }
  }

  async componentDidMount() {
  }

  selectTask(e, data) {
    this.setState({ current_tasks: data });
  }

  render() {
    return (
      <div className="hint-contentBox hint-openBox">
        <button className="close" onClick={this.props.ShowNote}>          
          <CustomImg src="gradiant-close.svg" className="img-close"
            alt="img-close" />
        </button>
        <div className="step-three h-100">
          <div className="contentHint tooltipBox h-100">
            <div className="row m-0 h-100">
              <div className="col-md-9">
                <div className="row text-center mb-auto">
                  <div className="col-xl-7 col-lg-6">
                    <h2
                      className="text-uppercase text-center newtext"
                    >
                      {this.state.current_tasks && this.state.current_tasks.length  > 0&& this.state.current_tasks.name}
                    </h2>
                  </div>
                  <div className="col-xl-5 col-lg-6">
                    {/* <h5 className="text-uppercase text-center newtext">
                      TOOLS USED
                    </h5>
                    <div className="boxImglist img-50">
                      <div className="content">
                        <div className="blackBox">
                          <img
                            src={require("../../../images/output-data.svg")}
                            alt="player-img"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="boxImglist img-50">
                      <div className="content">
                        <div className="blackBox">
                          <img
                            src={require("../../../images/input-data.svg")}
                            alt="player-img"
                          />
                        </div>
                      </div>
    </div> */}
                  </div>
                </div>

                <CustomScrollbars className="tesprofile-rightbox-scroll">
                  <div className="container-fluid mb-5">
                    {this.state.current_tasks && this.state.current_tasks.length > 0 
                    && this.state.current_tasks.Tooltips && this.state.current_tasks.Tooltips.length > 0 
                    && this.state.current_tasks["Tooltips"].map((data, index) => {
                      return (
                        <div className="row mb-5" key={index}>
                          <div className="col-xl-7 col-lg-6 text-center">
                            <div className="pr-0 h-100">
                              <hr className="mt-0 pt-0" />
                              <div className="content-height">
                                <h4 className="mt-4">{data.title}</h4>
                                <div>
                                  <div className="taskbox-sm mb-4 d-none">
                                    TASKS:
                                    <div className="taskBox">
                                      <div className="itembox">
                                        <a
                                          className="btn"
                                          href="javascript:void(0)"
                                        >
                                          <span></span>
                                        </a>
                                      </div>
                                      <div className="itembox">
                                        <a
                                          className="btn"
                                          href="javascript:void(0)"
                                        >
                                          <span></span>
                                        </a>
                                      </div>
                                      <div className="itembox">
                                        <a
                                          className="btn"
                                          href="javascript:void(0)"
                                        >
                                          <span></span>
                                        </a>
                                      </div>
                                    </div>
                                  </div>

                                  <p className="p-3">
                                    {parse(data.description)}
                                  </p>
                                </div>
                              </div>
                              <hr />
                            </div>
                          </div>
                          <div className="col-xl-5 col-lg-6 text-center">
                            {data.image && (
                              <div className="boxImg">
                                <div className="content">                                  
                                  <CustomImg src={data.image} alt={data.title} />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </CustomScrollbars>
              </div>
              <div className="col-md-3">
                <div className="toolsboxList">
                  <h4 className="text-uppercase text-center mt-3">Tools</h4>
                  <div className="inner-list">
                    <CustomScrollbars>
                      <ul>
                        {this.state.tasks &&
                          this.state.tasks.map((data, index) => {
                            return (
                              <li key={index} className={`${this.state.current_tasks && this.state.current_tasks.length > 0 &&
                               this.state.current_tasks.id == data.id ? 'active' : ''}`}>
                                <a className="toolList" href="javascript:void(0)" onClick={e => this.selectTask(e, data)}>
                                  <div className="boxImglist d-none">
                                    {/* <div className="content">
                                                                    <div className="blackBox"></div>
                                                                </div> */}
                                  </div>
                                  {data.name}
                                </a>
                              </li>
                            );
                          })}
                      </ul>
                    </CustomScrollbars>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Note;
