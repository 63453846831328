import React, { Component } from "react";
import "././../../../../Styles/Nuclear.scss";
import CustomImg from './../../../Global/CustomImg/CustomImg';
import ReactCodeInput from "./../../../Global/ReactCodeInput/ReactCodeInput";

class Nuclear extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AnswerSheet: [],
      CurrentTask: {},
      password: "",
      allCorrect: false,
      taskId: 0,
      answerWrong : false
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.CurrentTask !== state.CurrentTask) {
      let currentTask = props.CurrentTask;
      let currentTaskSolutions = currentTask.Solutions;
      if (currentTask.Solutions !== undefined) {
        return {
          AnswerSheet: currentTaskSolutions,
          taskId: currentTask.id
        };
      }
    }
    return null;
  }

  verifyAnswers = async value => {
    this.setState({
      password: value
    });
  };

  verifyFinalAnswers = async () => {
    if (
      this.state.AnswerSheet &&
      this.state.AnswerSheet.length > 0 &&
      this.state.AnswerSheet[0] &&
      this.state.AnswerSheet[0].answer_key &&
      this.state.AnswerSheet[0].answer_key.length > 0
    ) {
      const answers = this.state.AnswerSheet[0].answer_key[0];
      const passcode = answers.CODE;
      if (passcode.trim().toLowerCase() === this.state.password.trim().toLowerCase()) {
        this.setState({
          allCorrect: true
        });
        await this.props.markAsCompleted(this.state.taskId);
      }else{
          this.setState({
            answerWrong:  true
          });
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <div id="container" className="position-absolute d-flex jistify-content-center">
          <div className="page_content">
            <div className={"GeoThermal-holder nuclear-Box" + (this.state.SelectedSatellite > 0 ? 'show-overlay' : '')}>
              <div className="topBox">
                <div className="row h-100 d-flex align-items-center justify-content-center">
                  <div className="col-8">
                    <div className="row d-flex justify-content-center">
                      <div className="nuclearBox col">                        
                          <div className="nuclearTopBox">
                              <CustomImg src="flame-ORANGE.gif" className="rounded-circle" alt="Nuclear Smoke Orange" />
                          </div>                        
                        <div className="nuclearBottomBox">
                          <CustomImg src="nuclear-bottom-img.svg" className="rounded-circle" alt="Nuclear Image" />
                        </div>
                      </div>

                      <div className="nuclearBox col">                        
                          <div className="nuclearTopBox">
                            <CustomImg src="flame-GREEN.gif" className="rounded-circle" alt="Nuclear Smoke Green" />
                          </div>
                        
                        <div className="nuclearBottomBox">
                          <CustomImg src="nuclear-bottom-img.svg" className="rounded-circle" alt="Nuclear Image" />
                        </div>
                      </div>

                      <div className="nuclearBox col">                        
                          <div className="nuclearTopBox">
                            <CustomImg src="flame-PURPLE.gif" className="rounded-circle" alt="Nuclear Smoke Blue" />
                          </div>
                        
                        <div className="nuclearBottomBox">
                          <CustomImg src="nuclear-bottom-img.svg" className="rounded-circle" alt="Nuclear Image" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bottomBox">
                <div className="row h-100 justify-content-center d-flex justify-content-between">
                  <div className="col-2"></div>
                  <div className="col-8">
                    <div className="passwordBox mt-3">
                      <div className="inputBoxwrapper">
                        <ReactCodeInput  type="text" fields={6} pattern="\d*" className={"code-input" + (this.state.answerWrong ? " incorrect" :"") + (this.state.allCorrect ? " correct" : "")} onChange={this.verifyAnswers} disabled={this.state.allCorrect} value={this.state.password} />
                      </div>
                    </div>
                    <div className="text-center mt-4">
                      <button onClick={this.verifyFinalAnswers} className="red-btn btn-lg"><div className="redcolor easy-btn"><span>ENTER</span></div></button>
                    </div>
                  </div>
                  <div className="col-2"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Nuclear;
