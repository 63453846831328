import { connect } from 'react-redux';
import { markAsCompleted,setCurrentTask , markStepAsCompleted} from '../../../Redux/Modules/Tasks';
import States from '../../../Components/PageContainer/Games/States/States';

const mapStateToProps = ({ TaskReducer }) => {
    return {             
        CurrentTask : TaskReducer.CurrentTask
    };
};

const mapDispatchToProps = dispatch => ({
    markAsCompleted: (tid) => dispatch(markAsCompleted(tid)),
    setCurrentTask: (tid) => dispatch(setCurrentTask(tid)),
    markStepAsCompleted : (tid,itId) => dispatch(markStepAsCompleted(tid,itId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(States);