import React, { Component } from 'react';
import CustomImg from './../../../Global/CustomImg/CustomImg';
import '././../../../../Styles/FinalTask.scss';
import * as ReactDOM from 'react-dom';

class FinalTask extends Component {
    constructor(props) {
        super(props);
        this.state = {
            AnswerSheet: [],
            CurrentTask: {},
            taskId: 0,
            switchState:false,
            switchInit: false,
            switchComplete:false,
            connectionComplete:false,
            isLoadComplete : false,
            selectedIndex:-1,
            option:[
                {index:0,rposition:-1,lposition:-1,correct:false},
                {index:1,rposition:-1,lposition:-1,correct:false},
                {index:2,rposition:-1,lposition:-1,correct:false},
                {index:3,rposition:-1,lposition:-1,correct:false},
                {index:4,rposition:-1,lposition:-1,correct:false}]            
        };
    }

    handleSwitch = async (event)=>{
        console.log("switch Toggle",event);
        if(this.state.switchInit && this.state.connectionComplete && event.target.checked ){
            this.setState({
                switchState:true,
            },() => {
                setTimeout(() => {
                   this.props.markAsCompleted(this.state.taskId);
                }, 500);  
            });
        }else if(event.target.checked  && !this.state.switchInit){
            ReactDOM.findDOMNode(this.refs["onswitch"]).src = ReactDOM.findDOMNode(this.refs["onswitch"]).src + "?" + Math.floor(Math.random() * 10);
            this.setState({
                switchState:true,
                switchInit:true
            },() => {
                setTimeout(() => {
                    this.props.markStepAsCompleted(this.state.taskId, 1);
                    this.setState({
                        switchState:false
                    });
                }, 5000);  
            });
            
        }        
    }

    selectConnection = (key)=>{ 
        console.log("selectedindex",key);
        this.setState({
            selectedIndex: key
        });
    }

    connectTo =  (key)=>{  
        let option = this.state.option;
        console.log("connection To",key,option);
        if(this.state.selectedIndex == -1 || !option[this.state.selectedIndex]){
            return;
        } 
        // return if already connected right side
        if(option[this.state.selectedIndex].rposition>=0){
            return;
        }
        // return if already left side
        if(option[key].lposition>=0){
            return;
        }
        /*option[this.state.selectedIndex].rposition = -1;
        console.log(option[key].lposition);

        console.log("LEFT Selected Index ",option[this.state.selectedIndex]);
        console.log("RIGHT Selected Index ",option[key]);
        const rightPostion = option[this.state.selectedIndex].rposition; 
        const leftPostion = option[key].lposition;   
         console.log("Right==",rightPostion,"LEft Position==",leftPostion);
        // Reset the Left whole selection from right and left
        if(leftPostion >=0){     
            option[leftPostion].rposition = -1; // Reset left position of current right selection
            option[key].lposition = -1;           
            console.log("reset to ");
        }

         // Reset the Left whole selection from right and left
         if(rightPostion >=0){  
            option[rightPostion].lposition = -1; // Reset left position of current right selection
            option[this.state.selectedIndex].rposition = -1;          
            console.log("reset selected position right and left");
        }*/
      
        // Position current selection
        option[this.state.selectedIndex].rposition=key;
        option[key].lposition=option[this.state.selectedIndex].index;
        this.setState({
            option: option,
        });
        this.verifyConnection();      
    }



    verifyConnection = ()=>{
        let option = this.state.option;
        const optionList = option.filter(o=>o.rposition== -1);
        if(optionList.length == 0){
            const positions = (this.state.AnswerSheet[0] && this.state.AnswerSheet[0].answer_key && this.state.AnswerSheet[0].answer_key[0]) ?  this.state.AnswerSheet[0].answer_key[0].POSITIONS : [];
            for(let index in option){                
               option[index].correct = (option[index].lposition >=0 && option[index].lposition == positions[index]) ? true : false; 
               if(! option[index].correct){                
                option[option[index].lposition].rposition =-1;
                option[index].lposition = -1;
                
               }
            }

            let wrongConnection= option.filter((o)=>!o.correct);
            console.log(wrongConnection);
            if(wrongConnection && wrongConnection.length == 0){
                setTimeout(() => {
                 this.props.markStepAsCompleted(this.state.taskId, 2);
                }, 500);
                  
            }else{
                setTimeout(() => {
                    this.setState({
                        option: option,
                    });
                }, 500);
               
            }

        }
        this.setState({
            option: option,
        });
    }


    static getDerivedStateFromProps(props, state) {
        if (props.CurrentTask !== state.CurrentTask) {
            let currentTask = props.CurrentTask;
            let currentTaskSolutions = currentTask.Solutions;
            if (currentTask.Solutions !== undefined) {
                let switchInit = false;
                let connectionComplete = false;
                if (currentTask.Progress !== undefined && currentTask.Progress.length > 0) {
                    const verifyStepProgress = currentTask.Progress.filter(m => m.type.trim().toLowerCase() === "step");
                    if (verifyStepProgress.length === 1) {
                        switchInit = true;
                    }else if(verifyStepProgress.length === 2){
                        switchInit = true;   
                        connectionComplete = true;   
                    } 
                }
                return {
                    AnswerSheet: currentTaskSolutions,
                    taskId: currentTask.id,
                    switchInit:switchInit,
                    connectionComplete:connectionComplete,
                    isLoadComplete : true
                }
            }
        }
        return null;
    }
    
    render() {
        return (
            <React.Fragment>
                <div id="container" className="position-absolute d-flex jistify-content-center">
                    <div className="page_content">
                        {((this.state.isLoadComplete 
                            && !this.state.switchInit) ||  (this.state.isLoadComplete 
                                && this.state.switchInit && this.state.connectionComplete)) &&
                                 (
                                    <div className="gate finaltask-holder  h-100 justify-content-center">
                                        <div className="h-100 d-flex justify-content-center align-items-center"> 
                                            <div className="switch-bg text-center">
                                                <div className="main-swiche">
                                                    <input onChange={this.handleSwitch} id="switch" type="checkbox" className="swichbox" checked={this.state.switchState}/>
                                                    <label htmlFor={"switch"}>
                                                        {this.state.switchState}
                                                        <CustomImg src={"offswitch.svg"} className="offswitch" alt="off Switch" />
                                                        <CustomImg ref="onswitch" src={"onswitch.gif"} className="onswitch" alt="On Switch" />                                                        
                                                    </label>
                                                </div>
                                            </div> 
                                        </div>  
                                    </div>  
                                  )  
                                
                         }
                         

                         {this.state.isLoadComplete && this.state.switchInit && !this.state.connectionComplete &&
                            <div className="finaltask-holder finaltask-holder-cable">
                                <div className="h-100 d-flex justify-content-center align-items-center">
                                    <div className="swichBox stateSwitches">
                                        <div className="cable-left-bg">
                                            <div className="innerBackground text-center">
                                                <div className="swichBoxwrapper">
                                                    <div className="swichBoxwrapper">
                                                    {this.state.option.map((cable, key) =>
                                                    <div className={'swichItem '+ ((cable.rposition>=0) ? ' cable'+cable.index+''+cable.rposition: '') + ((this.state.selectedIndex === key) ? ' active':'') } key={'switch_state_left'+key} >
                                                        <input type="radio" name="connection" value={cable.index} checked={this.state.selectedIndex === cable.index} onChange={()=>this.selectConnection(key)}/>
                                                        <label htmlFor="Switch0"></label>
                                                        <div className={'cable'+(key)} onClick={()=>this.selectConnection(key)}></div>
                                                        <div className={'cable'+(key)+'0'}></div>
                                                        <div className={'cable'+(key)+'1'}></div>
                                                        <div className={'cable'+(key)+'2'}></div>
                                                        <div className={'cable'+(key)+'3'}></div>
                                                        <div className={'cable'+(key)+'4'}></div>
                                                    </div>
                                                    )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cable-right-bg">
                                            <div className="innerBackground text-center">
                                                <div className="swichBoxwrapper">
                                                    <div className="swichBoxwrapper">
                                                    {this.state.option.map((cable, key) =>
                                                    <div key={'final_state_right_'+key}  className="swichItem cable00 cable10" onClick={()=>this.connectTo(key)}>{cable.lposition}
                                                        <input ref={'final_state_right_'+key}  type="radio" name="connectTo" value={this.index} disabled={this.selectedIndex} />
                                                        <label htmlFor={'final_state_right_'+key}></label>
                                                        <div className="btng"></div>
                                                    </div>
                                                    )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                         }
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default FinalTask;