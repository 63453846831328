import React, { Component } from 'react';

class EndGame extends Component {

    constructor() {
        super();
        this.state = {
            isBonus: false,
            isExperienceGameFinished: false
        }
    }

    componentDidMount() {

        let bonusPoint = this.props.bonusPoint;
        let isBonus = false;

        if (bonusPoint < 0 && bonusPoint != "") {
            isBonus = true;
        }
        this.setState({
            isBonus: isBonus,
            isExperienceGameFinished: true
        });

    }
    render() {

        return (
            <div id="container" style={{left : "110px"}}>
                <div className="page_content">
                    <div className="videos-holder">
                        <div className="d-flex h-100">
                            <div className="row justify-content-center align-self-center w-100 text-center px-4">
                                <div className="col-12 endgame">
                                    {(this.state.isExperienceGameFinished && this.state.isBonus) && <span style={{ color: "#FFFFFF", fontSize: "100px" }}>
                                        {"Game Finished"}
                                    </span>
                                    }

                                    {(this.state.isExperienceGameFinished && !this.state.isBonus) && <span style={{ color: "#FFFFFF", fontSize: "100px" }}>
                                        {"Game Finished"}
                                    </span>
                                    }

                                </div></div>
                        </div>
                    </div></div></div>
        );
    }
}

export default EndGame;