import React, { Component } from 'react';
import '././../../../../Styles/States.scss';
import ReactCodeInput from './../../../Global/ReactCodeInput/ReactCodeInput'; 

class States extends Component {
    constructor(props) {
        super(props);
        this.state = {
            AnswerSheet: [],
            CurrentTask: {},
            taskId: 0,
            isCompleted: false,            
            isValid:true,
            password:"",
            stateComplete:false,
            switchComplete:false,
            passwordComplete:false,
            option:{
                states:[{label:'STATE 1',correct:false,value:"",length:9,class:'eight'},{label:'STATE 2',correct:false,value:"",length:9,class:'two'},{label:'STATE 3',correct:false,value:"",length:9,class:'three'},{label:'STATE 4',correct:false,value:"",length:9,class:'two three'}],
                password:false,
                switch:[{label:'A',correct:false,checked:false},{label:'B',correct:false,checked:false},{label:'C',correct:false,checked:false},{label:'D',correct:false,checked:false}]
            }
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.CurrentTask !== state.CurrentTask) {
            let currentTask = props.CurrentTask;
            let currentTaskSolutions = currentTask.Solutions;
            if (currentTask.Solutions !== undefined) {
                return {
                    AnswerSheet: currentTaskSolutions,
                    taskId: currentTask.id
                }
            }
        }
        return null;
    }

    verifyState = async (value, row) => {
        const states = (this.state.AnswerSheet[0] && this.state.AnswerSheet[0].answer_key && this.state.AnswerSheet[0].answer_key[0]) ?  this.state.AnswerSheet[0].answer_key[0].STATES : [];
        let option = this.state.option;
    
        if(value.length != states[row].length)
            return;
      
        option.states[row].correct = (value.trim().toLowerCase() === states[row].trim().toLowerCase()) ? true : false;

        const inCorrectState = option.states.find(s=>!s.correct);  

        this.setState({
            option: option,
            stateComplete: (!inCorrectState) ? true : false
        }); 
    }

    verifyToggle= async (event, row) => {
        const switchState = (this.state.AnswerSheet[0] && this.state.AnswerSheet[0].answer_key && this.state.AnswerSheet[0].answer_key[0]) ?  this.state.AnswerSheet[0].answer_key[0].SWITCH : "";
             
        let option = this.state.option;
        option.switch[row].checked = (event.target.checked) ? true : false;       

        const switches = option.switch.map((s)=>s.checked ? '1':'0').join("");
        this.setState({
            option: option,
            switchComplete : (switchState.trim() === switches.trim()) ? true : false
        });
    }

    verifyPassword = async (value) => {
        if(value.length != 5)
            return;
                   
        const password = (this.state.AnswerSheet[0] && this.state.AnswerSheet[0].answer_key && this.state.AnswerSheet[0].answer_key[0]) ?  this.state.AnswerSheet[0].answer_key[0].CODE : "";
        this.setState({
            password: value,
            passwordComplete : value.trim().toLowerCase() === password.trim().toLowerCase()
        });
    }

    submitAnswer = async ()=>{
        if(this.state.stateComplete && this.state.passwordComplete && this.state.switchComplete){
            this.setState({
                isValid: true,
                isCompleted : true,
            });
            await this.props.markAsCompleted(this.state.taskId);
        }
    }

    render() {
        return (
            <React.Fragment>
                   <div id="container" className="position-absolute d-flex jistify-content-center">
                        <div className="page_content">                        
                            <div className={"hydroElectric-holder states-holder"}>
                                <div className="row">
                                    <div className="col-9">
                                        <div>
                                            {this.state.AnswerSheet && this.state.AnswerSheet.length > 0 &&
                                                this.state.AnswerSheet[0].answer_key && this.state.AnswerSheet[0].answer_key.length > 0 && 
                                                this.state.AnswerSheet[0].answer_key[0].STATES && this.state.AnswerSheet[0].answer_key[0].STATES.length > 0 &&
                                                this.state.option.states.map((state, key) =>
                                                <div className="d-flex justify-content-center py-3 row" key={'state-'+key+1} ref={'row-'+key+1}>
                                                    <div className="col-2 text-right"><div className="title">{state.label} </div> </div>
                                                    <div className="col-10"><div className={'inputOuter '+state.class}><ReactCodeInput type='text' fields={(this.state.AnswerSheet[0] && this.state.AnswerSheet[0].answer_key && this.state.AnswerSheet[0].answer_key[0]) ?  this.state.AnswerSheet[0].answer_key[0].STATES[key].length : state.length} pattern="\d*"  onChange={(value) => this.verifyState(value,key)} disabled={state.correct} value={state.value} /></div></div>
                                                </div>
                                            )}
                                            <div className="d-flex justify-content-center py-3 row">
                                                <div className="col-2 text-right"><div className="title mt-2">Password</div></div>
                                                <div className="col-10"><div className="inputOuterpassword"><ReactCodeInput ref={"password"} type='text' fields={5} pattern="\d*" className={"code-input" + (this.state.password.length == 5 && this.state.passwordComplete ? " valid"  : "")} onChange={this.verifyPassword} disabled={this.state.passwordComplete} value={this.state.password} /></div></div>
                                            </div>
                                        </div> 
                                    </div>
                                    <div className="col-3 text-center d-flex justify-content-center">
                                        <div className="stateSwitches-wrapper">
                                            <div className="swichBox stateSwitches">
                                                <div className="charectorBox">
                                                    {  this.state.option.switch.map((sw, key) =>
                                                    <div key={'state_character-'+key+1} className="itemBox">{sw.label}</div>
                                                    ) }
                                                </div>
                                                <div className="innerBackground">
                                                    <div className="swichBoxwrapper">
                                                        { this.state.option.switch.map((sw, key) =>
                                                        <div key={'state-checkbox'+key+1} className="swichItem"><input type="checkbox" ref={"Switch"+key} checked={sw.checked} onChange={(e) => this.verifyToggle(e,key)}/><label htmlFor={"Switch"+key}></label></div>
                                                        ) }                                     
                                                    </div>
                                                </div>
                                            </div>   

                                            <button className="red-btn mt-4 submit-btn" onClick={this.submitAnswer} disabled={!this.state.stateComplete || !this.state.passwordComplete || !this.state.switchComplete}>
                                                <div className="redcolor easy-btn">
                                                <span>SUBMIT</span>
                                                </div>
                                            </button>                                               
                                        </div>
                                    </div>
                                </div>                                                      
                        </div>  
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default States;