import React, { Component } from 'react';
import * as ReactDOM from 'react-dom';
import '././../../../../Styles/Coal.scss';
import CustomImg from './../../../Global/CustomImg/CustomImg';
import Draggable from "react-draggable";
import { const_basePage } from '../../../Global/Constants/constants';
import bgImagew  from "./../../../../images/colicon.svg";
import jumpingman  from "./../../../../images/jumpingman.svg";

class Coal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            AnswerSheet: [],
            CurrentTask: {},
            taskId: 0,
            controlledPosition : { x: 0, y: 0 },
            leftCounter : 0,
            upCounter : 0,
            downCounter : 0,
            rightCounter : 0,
            moveSize : 64,
            blocks : [1,11,10,14,15,16,17,20,23,24,25,30,40,35,39,45,55,36,46,56,65,75,84,85,94,95 , 33,34,43,44,57,58,78,79,80,82,90,92,96],
            hotspots : [21,83,93,61,62,63,42,13,18,26,38,59,77,89,97],
            currentBlock : 91,
            isGameCompleted : false
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.CurrentTask !== state.CurrentTask) {
            let currentTask = props.CurrentTask;
            let currentTaskSolutions = currentTask.Solutions;
            if (currentTask.Solutions !== undefined) {
                return {
                    AnswerSheet: currentTaskSolutions,
                    taskId: currentTask.id
                }
            }
        }
        return null;
    }

    positionChange = async (type) => {
        if(this.state.isGameCompleted)
        {
            return;
        }

        let currentLeftCounter = this.state.leftCounter;
        if(currentLeftCounter === 0 && type.toLowerCase() === "left")
        {
            return;
        }

        let currentRightCounter = this.state.rightCounter;
        if(currentRightCounter === 9 && type.toLowerCase() === "right")
        {
            return;
        }

        let currentUpCounter = this.state.upCounter;
        if(currentUpCounter === 9 && type.toLowerCase() === "up")
        {
            return;
        }

        let currentDownCounter = this.state.downCounter;
        if(currentDownCounter === 0 && type.toLowerCase() === "down")
        {
            return;
        }

        let xUpdate = this.state.controlledPosition.x;
        let yUpdate = this.state.controlledPosition.y;
        let currentBlockNumber = this.state.currentBlock;
        if(type.toLowerCase() === "up")
        {
            currentBlockNumber = currentBlockNumber - 10;
            currentUpCounter = currentUpCounter + 1;
            currentDownCounter = currentDownCounter + 1;
            yUpdate = yUpdate - this.state.moveSize;
        }else if(type.toLowerCase() === "right")
        {
            currentBlockNumber = currentBlockNumber + 1;
            currentRightCounter = currentRightCounter + 1;
            currentLeftCounter = currentLeftCounter + 1;
            xUpdate = xUpdate + this.state.moveSize;
           
        }else if(type.toLowerCase() === "left")
        {
            currentBlockNumber = currentBlockNumber - 1;
            currentLeftCounter = currentLeftCounter - 1;
            currentRightCounter = currentRightCounter - 1;
            xUpdate = xUpdate - this.state.moveSize;
        }else if(type.toLowerCase() === "down")
        {
            currentUpCounter = currentUpCounter - 1;
            currentDownCounter = currentDownCounter -  1;
            yUpdate = yUpdate + this.state.moveSize;
            currentBlockNumber = currentBlockNumber + 10;
        }

        if(this.state.blocks.indexOf(currentBlockNumber) > -1)
        {
            return;
        }

        if(this.state.hotspots.indexOf(currentBlockNumber) > -1)
        {
            this.setState({
              controlledPosition : { x: 0, y: 0 },
                leftCounter : 0,
                upCounter : 0,
                downCounter : 0,
                rightCounter : 0,
                currentBlock : 91
             });
            return;
        }
       
        this.setState({
            controlledPosition : { x: xUpdate, y: yUpdate },
            leftCounter : currentLeftCounter,
            rightCounter : currentRightCounter,
            upCounter : currentUpCounter,
            downCounter : currentDownCounter,
            currentBlock : currentBlockNumber
        }, async () => {
            if(currentBlockNumber === 100 && type.toLowerCase() === "right")
            {
                this.setState({
                    isGameCompleted : true
                });
                await this.props.markAsCompleted(this.state.taskId);
                return;
            }
        });
    }

    
  componentDidMount() {
        const containerPositions = ReactDOM.findDOMNode(this.refs["containerBox"]).getBoundingClientRect();
        this.setState({
            bounds: {
                left: containerPositions.left, 
                top:  containerPositions.top, 
                right: containerPositions.right, 
                bottom: containerPositions.bottom
            }
        });

        document.addEventListener("keydown", this.handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    handleKeyDown = (e) => {
        if(this.state.isGameCompleted)
        {
            return;
        }
        if(e.keyCode === 38)
        {
            this.positionChange("up");
        }else if(e.keyCode === 40)
        {
            this.positionChange("down");
        }else if(e.keyCode === 37)
        {
            this.positionChange("left");
        }else if(e.keyCode === 39)
        {
            this.positionChange("right");
        }
    }

    render() {
        let counter = 0;
        return (
            <React.Fragment>
               <div id="container" className="position-absolute d-flex jistify-content-center">
                    <div className="page_content position-absolute" ref={"containerBox"}>
                        <div className="gate h-100 d-flex flex-column">
                            {/* <h3 className="text-center mt-5 text-uppercase mb-0">title goes here</h3> */}
                            <div className="row">
                                <div className="col-md-3 text-center pr-0">
                                   <div className="h-100">
                                       <div className="arrowbox">
                                   <button className="col-uparrow border-0" onClick={() => this.positionChange("up")}></button><br />
                                   <button className="col-leftarrow border-0"  onClick={() => this.positionChange("left")}></button>
                                   <button className="col-centerpoint border-0"></button>
                                   <button className="col-rightarrow border-0" onClick={() => this.positionChange("right")}></button>
                                   <br />
                                   <button className="col-downarrow border-0" onClick={() => this.positionChange("down")}></button>
                                   </div>
                                   <div className="col-startarrow text-uppercase">start</div>
                                   </div>
                               </div>
                                <div className="col-md-6 px-0">
                                <div className="mx-auto h-100 d-flex flex-wrap align-items-center">
                                   
                                   <div className="board-wrapper mx-auto" style={{position:"relative"}} >
                                       {[...Array(10)].map((data,index) => {
                                          return <div className="d-flex" key={index}>
                                               {[...Array(10)].map((sdata,sindex) => {
                                                   counter = counter + 1;
                                                   let bgColor = "rgba(255,190,0,0.5)";
                                                   let bgImage = "rgba(255,190,0,0.5)";
                                                   if(this.state.blocks.indexOf(counter) > -1)
                                                   {
                                                      bgColor = "rgba(114,86,16,0.5)";
                                                      bgImage = bgImagew;
                                                   }
                                                   return <div id={"box" + counter} key={sindex} style={{height : 60, width : 60, background: bgColor, backgroundImage: "url(" + bgImage + ")", backgroundPosition: "center center" , backgroundRepeat: "no-repeat" }} className="spacingbox flex-fill">&nbsp;</div>
                                               })}
                                           </div>
                                       })}
                                        <Draggable position={this.state.controlledPosition}  scale={const_basePage.scale}>
                                           <div className="itemBoxImage" style={{height :75, width : 50, backgroundImage: "url(" + jumpingman + ")", backgroundPosition: "center center" , backgroundRepeat: "no-repeat", zIndex :"55" , position:"absolute" , bottom : 8, left : 9}}  ref="itemBoxImage" className="itemBox">
                                               &nbsp;
                                           </div>
                                       </Draggable>
                                   </div>
                            </div>
                                </div>
                                <div className="col-md-3 text-left pl-0">
                                    <div className="col-finisharrow text-uppercase">
                                    finish
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Coal;