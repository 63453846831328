import { TaskApi, VideoApi, MessageApi } from "../../Providers/Api";
import { initilizeUserName } from "./../Modules/User";
import History from "../../Providers/History";

const GET_CURRENT_TASK = "teg/GET_CURRENT_TASK";
const SET_CURRENT_TASK = "teg/SET_CURRENT_TASK";
const MARK_CURRENT_TASK_VIDEO_PLAYED = "teg/MARK_CURRENT_TASK_VIDEO_PLAYED";
const MARK_CURRENT_TASK_MESSAGE_POPPED = "teg/MARK_CURRENT_TASK_MESSAGE_POPPED";
const MARK_CURRENT_TOOLTIP_MESSAGE = "teg/MARK_CURRENT_TOOLTIP_MESSAGE";
const MARK_CURRENT_TASK_COMPLETED = "teg/MARK_CURRENT_TASK_COMPLETED";
const MARK_EXPERIENCE_COMPLETED = "teg/MARK_EXPERIENCE_COMPLETED";
const UPDATE_TASK_PROGRESS = "teg/UPDATE_TASK_PROGRESS";
const GET_ALL_TASK = "teg/GET_ALL_TASK";
const TOGGLE_NOTES = "teg/TOGGLE_NOTES";
const MARK_ALL_TASK_COMPLETED = "teg/MARK_ALL_TASK_COMPLETED";
const MARK_EXPERIENCE_VIDEO_PLAYED = "teg/MARK_EXPERIENCE_VIDEO_PLAYED";
const UPDATE_TOTAL_SCORE = "teg/UPDATE_TOTAL_SCORE";
const UPDATE_LAST_ACTIVITY = "teg/UPDATE_LAST_ACTIVITY";

const SET_ACCESS_TOKEN = "teg/SET_ACCESS_TOKEN";
const UNSET_ACCESS_TOKEN = "teg/UNSET_ACCESS_TOKEN";
const SET_ENROLLMENT_STATUS = "teg/SET_ENROLLMENT_STATUS";

const UPDATE_SCORE = "teg/UPDATE_SCORE";
const UPDATE_CLUE_REQUEST = "teg/UPDATE_CLUE_REQUEST";
const UPDATE_BONUS_POINT = "teg/UPDATE_BONUS_POINT";
const MARK_IS_GAME_FINISHED= "teg/MARK_IS_GAME_FINISHED";
const UPDATE_CLUE_COUNT = "teg/UPDATE_CLUE_COUNT";
const SUBTRACTHINTS = "SUBTRACTHINTS";
const MARK_STEP_AS_READ = "MARK_STEP_AS_READ";
//const SET_ACCESS_TOKEN = "teg/SET_ACCESS_TOKEN";
const SET_VIDEOS = "teg/SET_VIDEOS";
const USER_ENROLLED = "teg/USER_ENROLLED";

const initialState = {
  CurrentTask: {},
  tasks: [],
  isDashboardActive: true,
  showNotes: false,
  isGameFinish: false,
  isExperienceCompleted: true,
  isAuthenticated: false,
  isExperienceGameFinished: false,
  totalScore: 0,
  totalTimeElapsed: 0,
  isBonus: false,
  bonusPoint: "",
  clue_remaining: 0,
  isMsgSection : false,
  video_start : "",
  video_end : "",
  isEnrolled : false
};

const TaskReducer = (state = initialState, action) => {
  let action_error = null;

  if (typeof action.error !== "undefined") {
    action_error = action.error;
  }

  switch (action.type) {
    case SET_ACCESS_TOKEN:
      return { ...state, isAuthenticated: true };
    case USER_ENROLLED:
        return { ...state, isEnrolled: true };
    case UNSET_ACCESS_TOKEN:
      return { ...state, isAuthenticated: false };
    case GET_CURRENT_TASK:
      return {
        ...state
      };
    case SET_ENROLLMENT_STATUS:      
      return {
        ...state,
        clue_remaining: action.data.clue_remaining,
        isExperienceCompleted: !action.data.is_new
      };
    case SET_CURRENT_TASK:
      let currentTask = state.CurrentTask;
      currentTask = action.data.task;
      return {
        ...state,
        CurrentTask: currentTask
      };
    case GET_ALL_TASK:
      return {
        ...state,
        tasks: action.data
      };
    case MARK_CURRENT_TASK_COMPLETED:
      const curntTask = state.tasks.map(el =>
        el.id === action.data ? { ...el, isCompleted: true } : el
      );
      return {
        ...state,
        tasks: curntTask,
        isDashboardActive: true
      };
    case SET_VIDEOS:      
      return {
        ...state,
        video_start: action.video_start,
        video_end: action.video_end
    };  
    case MARK_CURRENT_TASK_VIDEO_PLAYED:
      return {
        ...state,
        CurrentTask: { ...state.CurrentTask, isVideoPlayed: true }
      };
    case MARK_EXPERIENCE_COMPLETED:
      return {
        ...state,
        isExperienceCompleted: true
      };
    case MARK_CURRENT_TASK_MESSAGE_POPPED:
      return {
        ...state,
        CurrentTask: { ...state.CurrentTask, isMsgPoped: true  },
        isMsgSection : action.data
      };
      case MARK_CURRENT_TOOLTIP_MESSAGE:
        return {
          ...state,
          isMsgSection : false 
        };
    case TOGGLE_NOTES:
      const status = !state.showNotes;
      return {
        ...state,
        showNotes: status
      };
    case MARK_ALL_TASK_COMPLETED:
      return {
        ...state,
        isGameFinish: action.data,
        isExperienceGameFinished: action.data,
        isDashboardActive: false
      };
    case MARK_IS_GAME_FINISHED:
      return {
        ...state,
        isGameFinish: action.data,
      }
    case UPDATE_TOTAL_SCORE:
      return {
        ...state,
        totalScore: action.data
      };
    case UPDATE_LAST_ACTIVITY:
      return {
        ...state,
        totalTimeElapsed: action.data
      };
    case UPDATE_SCORE:
      return {
        ...state,
        totalScore: action.data
      };
    case UPDATE_BONUS_POINT:
      return {
        ...state,
        bonusPoint: action.data
      };
    case UPDATE_CLUE_REQUEST:
      const currentTaskclues = state.CurrentTask.Clues.map(el =>
        el.clue_id === action.data.clue_id
          ? { ...el, UserClueRequest: action.data }
          : el
      );
      return {
        ...state,
        //CurrentTask: { ...state.CurrentTask, isMsgPoped: true }
        CurrentTask: {
          ...state.CurrentTask,
          Clues: currentTaskclues
        }
      };
    case SUBTRACTHINTS:
      return {
        ...state,
        clue_remaining: state.clue_remaining - 1
      };
    case UPDATE_CLUE_COUNT:
      return {
        ...state,
        clue_remaining: state.clue_remaining + 1
      };
    case MARK_STEP_AS_READ:
      const updatedProgress = state.CurrentTask.Progress.concat({
        progress_id :   state.CurrentTask.Progress.length + 1,
        type: "STEP",
        item_id : action.data
      });
      return {
        ...state,
        CurrentTask: { ...state.CurrentTask, Progress: updatedProgress }
      };      
    default:
      return state;
  }
};

export const _dispatch_subtractHint = data => {
  return {
    type: SUBTRACTHINTS,
    data
  };
};

export const subtractHint = () => dispatch => {
  try {
    // initialState.remainingHints -= 1;
    // initialState.extraHintsUsed=initialState.extraHintsAdded>0?initialState.extraHintsUsed+=1 : initialState.extraHintsUsed;
    dispatch(_dispatch_subtractHint());
  } catch (e) {
    console.log("Error in fetching Data");
  }
};

export const getAllTasks = () => async dispatch => {
  try {
    let response = await TaskApi.getAllTasks();
    dispatch({ type: GET_ALL_TASK, data: response });

    const complTask = response
      .filter(data => {
        return !data.isCompleted;
      })
      .slice(0, 1)[0];
    if (complTask === undefined) {
      dispatch({ type: MARK_ALL_TASK_COMPLETED, data: true });
      await TaskApi.markExperienceCompleted();
      History.push("/finish");
    } else {
		//if(complTask.id === 6) { complTask.video = null;  }
		//if(complTask.video === null ) {complTask.isVideoPlayed = true; }
		
		dispatch({ type: SET_CURRENT_TASK, data: { task: complTask } });
		return response;
    }
  } catch (e) {
  
  }
};

export const getEnrolled = () => async dispatch => {
  try {
    let response = await TaskApi.getEnrolled();
    if(response.token)
    {
      dispatch({ type: USER_ENROLLED });
    }
    // console.log("API calld  dddd ");
    dispatch(initilizeUserName(response.User.username, response.User.nickname));
    dispatch({
      type: SET_VIDEOS,
      video_start :  response.Experience.video_start,
      video_end :  response.Experience.video_end,
    });
    dispatch({ type: SET_ENROLLMENT_STATUS, data: response });
    dispatch({
      type: UPDATE_TOTAL_SCORE,
      data: response.score === null ? 0 : response.score
    });
    dispatch({
      type: UPDATE_LAST_ACTIVITY,
      data: response.total_time
    });

    dispatch({
      type: UPDATE_BONUS_POINT,
      data: response.Experience.bonus_point
    });

    if (response.experience_endtime != null ) {    
      dispatch({
        type: MARK_IS_GAME_FINISHED,
        data: true
      });
      History.push('/finish');
    }
    
  } catch (e) {
    //console.log("Error in fetching Data",e);
  }
};

export const getCurrentTask = () => dispatch => {
  try {
    let response = TaskApi.getCurrentTask();
    dispatch({ type: GET_CURRENT_TASK, data: response });
    return response;
  } catch (e) {
    // console.log("Error in fetching Data");
  }
};

export const setCurrentTask = taskid => dispatch => {
  try {
	let response = TaskApi.getTaskById(taskid);
	//if(response.id === 6) { response.video = null;  }
//	if(response.video === null ) {response.isVideoPlayed = true; }

    dispatch({ type: SET_CURRENT_TASK, data: response });
    return response;
  } catch (e) {
    // console.log("Error in fetching Data");
  }
};

export const markStepAsCompleted = (taskid, itemId) => async (dispatch) => {
  try {
    await TaskApi.markStepAsCompleted(taskid, itemId);
    dispatch({ type: MARK_STEP_AS_READ, data: itemId });
  } catch (e) {
    // console.log("Error in fetching Data");
  }
};

export const markAsCompleted = taskid => async (dispatch, getState) => {
  try {
    /** Need to code API to mark user's task a read
     *
     * Mark current task as read and load next task
     */
    //await TaskApi.markAsCompleted(taskid);
    dispatch({ type: MARK_CURRENT_TASK_COMPLETED, data: taskid });
    let response = await TaskApi.markAsCompleted(taskid);
    let totalScore = response.score;
    dispatch({ type: UPDATE_TOTAL_SCORE, data: totalScore });

    const currentState = getState();
    if (taskid < 24) {
      let tasks = currentState.TaskReducer.tasks
        .filter(data => {
          return data.id > taskid;
        })
		.slice(0, 1)[0];
		//if(tasks.id === 6) { tasks.video = null; }
		//if(tasks.video === null ) {tasks.isVideoPlayed = true; }

	  dispatch({ type: SET_CURRENT_TASK, data: { task: tasks } });
	 
    } else {
      let res = await TaskApi.markExperienceEnd();  
     
      if(res.data.enrollment.score) {         
        dispatch({ type: UPDATE_TOTAL_SCORE, data: res.data.enrollment.score });   
      }
      dispatch({ type: MARK_IS_GAME_FINISHED, data: true });
   
    }
  } catch (e) {
    // console.log("Error in fetching Data"); 
  }
};

export const markCurrentTaskVideoPlayed = type => async (
  dispatch,
  getState
) => {
  try {
    const currentState = getState();
    let currentTask = currentState.TaskReducer.CurrentTask;

    VideoApi.markVideoAsRead(currentTask.id);
    dispatch({ type: MARK_CURRENT_TASK_VIDEO_PLAYED, data: true });
  } catch (e) {
    // console.log("Error in fetching Data");
  }
};

export const markExperienceCompleted = () => async dispatch => {
  try {
    dispatch({ type: MARK_ALL_TASK_COMPLETED, data: true });
    let res = await TaskApi.markExperienceCompleted();

    History.push("/finish");
    return res;
    //
  } catch (e) {
    //console.log("Error in fetching Data",e);  
  }
};

export const markExperienceAsRead = () => dispatch => {
  try {
    TaskApi.markExperienceAsRead();
    dispatch({ type: MARK_EXPERIENCE_COMPLETED });
  } catch (e) {
    // console.log("Error in fetching Data");
  }
};

export const markTaskMessageAsPopped = (msgId, flag) => dispatch => {
  try {
    MessageApi.markMessageAsRead(msgId);
    dispatch({ type: MARK_CURRENT_TASK_MESSAGE_POPPED, data: flag });
  } catch (e) {
    // console.log("Error in fetching Data");
  }
};

export const toggleNotes = () => dispatch => {
  try {
    // console.log("called");
    dispatch({ type: TOGGLE_NOTES });
  } catch (e) {
    // console.log("Error in fetching Data");
  }
};

export const markLastActivity = () => dispatch => {
  try {
    let res = TaskApi.markLastActivity();
    dispatch({ type: UPDATE_LAST_ACTIVITY, data: res.timeElapsed });
  } catch (e) {
    // console.log("Error in fetching Data");
  }
};

export const getTimeBonus = () => dispatch => {
  try {
    // let res = TaskApi.getTimeBonus();
    let res = {
      totalTime: 2000,
      specifiedValue: 5000,
      isBonus: true
    };
    return res;
  } catch (e) {
    // console.log("Error in fetching Data");
  }
};

export const _dispatch_UpdateScore = data => {
  return {
    type: UPDATE_SCORE,
    data
  };
};

export const updateScore = score => dispatch => {
  try {
    dispatch(_dispatch_UpdateScore(score));
  } catch (e) {
    console.log("Error in fetching Data");
  }
};

export const _dispatch_updateClueRequest = data => {
  return {
    type: UPDATE_CLUE_REQUEST,
    data
  };
};

export const updateClueRequest = data => dispatch => {
  try {
    dispatch(_dispatch_updateClueRequest(data));
  } catch (e) {
    console.log("Error in fetching Data");
  }
};

export const _dispatch_updateClueCount = data => {
  return {
    type: UPDATE_CLUE_COUNT,
    data
  };
};

export const updateClueCount = data => dispatch => {
  try {
    dispatch(_dispatch_updateClueCount(data));
  } catch (e) {
    console.log("Error in fetching Data");
  }
};

export const markToolTipMsgAsRead = () => dispatch => {
  dispatch({ type: MARK_CURRENT_TOOLTIP_MESSAGE });
};

export default TaskReducer;
//export const { sortChange, sortReset, singleSortReset } = sortable.actionCreators;
